.member-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.member-welcome-img {
  width:100%;
  height:350px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.member-welcome-content-wrapper {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.member-welcome-content {
  padding:12px;
}
.member-welcome-title-wrapper,.member-welcome-text-wrapper,.member-welcome-button-wrapper {
  overflow: hidden;
}
.member-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin:5px 2px;
  letter-spacing: 1px;
  animation:memberwelcometitle .8s ease;
}
@keyframes memberwelcometitle{
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
.member-welcome-text {
  color:#eee;
  text-align: center;
  font-weight: 550;
  font-size: 1.1rem;
  animation: memberwelcometext .7s ease;
}
@keyframes memberwelcometext{
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
.member-welcome-button {
  border: none;
  background: var(--color-primary);
  font-size: 1.2rem;
  font-weight:900;
  padding:14px 35px;
  border-radius:3px;
  color:#fff;
  display: block;
  margin:12px auto;
  letter-spacing:1px;
  transition: all .05s ease;
  animation: memberwelcomebutton .8s ease;
}
@keyframes memberwelcomebutton{
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateX(0);
  }
}
.member-welcome-button:hover {
  transform: scale(1.009);
}
.member-welcome-button:focus {
  background: var(--color-333);
}

/*member wrapper and member avatarcard*/
.members-avatar-wrapper {

}
.members-avatar-content {
  width:96%;
  max-width: 900px;
  margin:10px auto;
  padding: 5px;
  
}
.members-avatar-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 900;
  margin:8px;
  padding: 5px;
  
  
}
.members-avatar-title-itself {
  color:var(--color-primary);
}
.members-avatar {
  margin:10px 5px;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.member-avatar-card {
  flex:1 1 250px;
  max-width: 300px ;
  margin:10px 5px;
  border:1px solid #ddd;
  transition: all .2s ease;
  border-radius: 4px;
  
}
.member-avatar-card:hover {
  transform: scale(1.009);
  box-shadow: 0 0 5px #ddd;
}
.member-avatar-img {
  width:100%;
  height: 320px;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}
.member-avatar-body {
  margin:10px;
  padding: 5px;
  color: grey;
}
.member-name {
  color: var(--color-charcoal);
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  padding:.1rem;
  letter-spacing: .4px ;
  text-transform: capitalize;
  width: 100%;
  
}
.member-quote {
  padding: 5px 5px 5px 8px;
  margin: 3px;
  font-size: .95rem;
  width:100%;
  text-align: center;
  text-transform: capitalize;
}
.member-avatar-link {
  border-radius: 50%;
}
.member-avatar-media-icon {
  transition: all .1s ease;
}
.member-avatar-media-icon:hover {
  transform: translateY(-2px);
}




/*contact*/
.conflict-contact-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.conflict-contact-welcome-img {
  width:100%;
  height:350px;
  object-fit: cover;
  object-position: 0 90%;
  overflow: hidden;
}
.conflict-contact-welcome-content-wrapper {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.conflict-contact-welcome-content {
  padding:12px;
}
.conflict-contact-welcome-title-wrapper,.conflict-contact-welcome-text-wrapper {
  overflow: hidden;
}
.conflict-contact-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin:5px 2px;
  letter-spacing: 3px;
  animation: contactwelcometitle .7s ease;
}
@keyframes contactwelcometitle {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
.conflict-contact-welcome-text {
  color:#eee;
  text-align: center;
  font-weight: 550;
  font-size: 1.1rem;
  animation: contactwelcometext .7s ease;
}
@keyframes contactwelcometext {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}


/*contact info and form*/

.conflict-contact-wrapper {
  padding: 10px;
  background: #fff;
 
}

.contact {
  width:92%;
  max-width:700px;
  margin:10px auto;
  padding: 5px;
  display:flex;
  justify-content: space-around;
  flex-flow: column nowrap;
}

.conflict-contact-info {
  padding: 8px 0;
}
.conflict-contact-info-title {
  font-size: 2.5rem;
  font-weight: 800;
  padding: 5px 5px 5px 12px;
  font-family: Oswald,Sans-Serif;
}
.conflict-contact-info-itself {
  color:var(--color-primary);
}

.conflict-contact-info-content {
  margin:5px;
  padding:5px;
}

.conflict-contact-information {
  margin:5px 4px;
  padding:4px;
  
}
.conflict-contact-information-title {
   color:grey;
   font-size:1rem;
   font-weight: 450;
}
.conflict-contact-information-icon {
  width:15px;
  height:15px;
  fill:var(--color-primary);
  margin:5px 10px;
  transform: translateY(10px);
}
.conflict-contact-information-data {
  color:var(--color-primary);
  margin:8px 10px;
  font-size: .95rem;
  text-indent: 15px;
   font-weight:410;
}

/*contact form*/
.contact-form-title {
  font-family: Oswald,Sans-Serif;
  font-size: 2.5rem;
  font-weight: 800;
}
.contact-form-title-itself {
  color:var(--color-primary);
}
.conflict-contact-form {
  margin:10px auto;
  padding: 12px 8px;
  
}
.contact-form-input-wrapper {
  margin:8px auto;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}
.contact-form-input {
  display: block;
  height: 50px;
  border:1px solid #ddd;
  border-radius: 5px;
  flex:1 1 120px;
  max-width: 400px;
  margin:10px 2px;
  background: transparent;
  padding: 5px 5px 8px 15px;
  transition: all .15s ease;
}
.contact-form  ::placeholder {
  text-indent: 12px;
  color:grey;
}

.contact-form-input:focus {
  border:1px solid #ddd;
  outline: none;
  box-shadow: 0 0 5px #ddd;
}
.contact-form-subject {
  display: block;
  width: 100%;
  padding: 5px 5px 5px 10px;
  height: 60px;
  border:1px solid #ddd;
  margin:8px 2px;
  border-radius: 3px;
  transition: all .15s ease;
}
.contact-form-subject:focus {
  outline: none;
  box-shadow: 0 0 5px #ddd;
}

.contact-form-textarea {
  width:100%;
  border-radius: 4px;
  border:1px solid #ddd;
  background: transparent;
  height:200px;
  font-family: Lato,Sans-Serif;
  padding: 10px 5px 10px 12px;
  margin:10px 2px;
  transition: all .15s ease;
  color:var(--color-charcoal);
}

.contact-form-textarea:focus {
  border: 1px solid #ddd;
  outline: none;
  box-shadow: 0 0 8px #ddd;
}
.contact-form-invalid {
  font-size:.9rem;
  color: var(--color-secondary);
  font-weight:390;
}
.contact-form-success {
  font-size:.9rem;
  color: var(--color-green);
  font-weight:390;
}
.contact-form-button {
  margin:.9rem .1rem .5rem 1rem ;
  padding: .85rem 1.3rem ;
  border: none;
  border-radius: 3px;
  background: var(--color-333);
  font-size: 1rem;
  font-weight: 600;
  color:#fff;
  height: 50px;
  transition: all .02s ease;
  letter-spacing: 1px;
}
.contact-form-button:hover {
  opacity: .9;
}
.contact-form-button:focus {
  background: var(--color-primary);
}





/*history*/
.our-history-wrapper {
  
}
.our-history {
  width:98%;
  max-width: 850px;
  margin:.7rem auto;
  padding: .8rem .4rem;
}


/*our history quote*/
.our-history-quote-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.our-history-quote {
  width:100%;
  flex:5 1 280px;
  margin:.3rem;
  padding:.7rem .2rem;
  font-size: 1rem;
  color:grey;
}
.our-history-quote-paragraph {
  text-indent:.2rem;
  padding:.05rem .1rem;
  margin: .45rem .1rem;
}
.our-history-quote-img-wrapper {
  flex:3 1 400px;
  margin:.3rem;
}
.our-history-quote-img {
  display: block;
  width:90%;
  max-width: 400px;
  margin:.3rem auto;
  height:400px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  filter:saturate(130%);
  border-radius: 2px 40% 2px 40%;
  border-radius:50%;
  box-shadow: 2px 3px 10px #ddd;
  
}


/*our history content wrapper*/
.our-history-content-wrapper {
  padding: .8rem .6rem;
}
.our-history-content {
  display: flex;
  flex-flow: column wrap;
  width:100%;
}
.our-history-content-img-wrapper {
  flex:1 1 310px ;
}
.our-history-content-img {
  width:100%;
  min-height: 200px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  filter: saturate(130%);
  box-shadow: 0 0 2px #fff;
}
.our-history-content-paragraph {
  display: block;
  width: 100%;
  margin:.2rem .5rem;
  
  font-size: .9rem;
  color:grey;
  padding: .3rem .4rem;
}
/*buttons*/
.our-history-button-holder {

}
.our-history-button {
  display: inline-block;
  padding: .7rem 1rem;
  background: transparent;
  border:none;
  margin:1rem .3rem .2rem .3rem ;
  box-shadow: 0 3px 10px #ddd;
  border-radius:3px;
  transition: all .1s ease;
  font-family: serif;
  
}
.our-history-button:hover {
  opacity: .9;
}
.our-history-button:focus {
  background: var(--color-333);
}
.our-history-readmore-button {
  background: var(--color-primary);
  color: #fff;
}
.our-history-document-button {
  background: var(--color-green);
  color:#fff;
}
.our-history-button-icon {
  transform:translateY(7px);
  display: inline-block;
  margin:2px 6px;
  fill:#fff;
}




/*our history story*/
.our-history-story-wrapper {
  margin:.7rem .5rem;
  padding: .4rem .5rem;
  font-size: .95rem;
  display: flex;
}
.our-history-story {
  display: block;
  width:100%;
  padding: .6rem .1rem ;
  margin:.7rem .1rem;
  color:grey;
}
.our-history-full-img {
  display: block;
  margin:.5rem auto;
  width:100%;
  min-height: 200px;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  filter:saturate(115%);
}
.our-history-story-title {
  margin:.5rem .2rem;
  text-indent:.65rem;
  color:var(--color-primary);
  font-family:oswald,Sans-Serif;
}
.our-history-story-paragraph {
  width:100%;
  margin:.8rem 0;
  padding: .4rem 0;
}
.our-history-story-bullet-note {
  margin:.2rem .1rem;
  text-indent:.3rem;
  color:#333;
}
.our-history-story-bullet {
  color:var(--color-primary);
}
.bible-quote {
  font-style: italic;
}
.aboutus-subtitle {
  margin:.5rem .2rem;
}

@media only screen and (max-width:900px) {
/*member*/


/*contact*/
.conflict-contact-title { 

}

}

@media only screen and (max-width:780px) {
  /*member */
.members-avatar-content {
  width:100%;
}


/*contact*/
.conflict-contact-welcome-img {
  height:300px;
}

.conflict-contact-welcome-title {
  font-size: 3rem;
  
}

.conflict-contact-wrapper{
  width:98%;
}
.conflict-contact-title {
  
}
.contact-form-title {
  
}
.contact-form-title-itself {
  display: block;
  
}
}


@media only screen and (max-width:600px) {
  /*member*/

.member-avatar-card {
  max-width: 450px;
}



/*contact*/
.contact {
  width:100%;
}
.conflict-contact-title {
  
}



/*our history*/
.our-history-quote-img {
  width:100%;
  height: auto;
  min-height: 120px;
}
.our-history-content {
  flex-flow: row-reverse wrap;
}
}