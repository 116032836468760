.admin-add-blog-wrapper {
  position: relative;
  overflow: hidden;
  padding: 1rem .2rem;
}

.admin-add-blog-title {
  text-align: center;
  font-size: 1.6rem;
  color: var(--color-charcoal);
  margin:.75rem .1rem;
}

.add-blog-form-block {
  display: flex;
  flex-flow:row wrap ;
  margin:.85rem .3rem;
}
.add-blog-form-label {
  text-indent:.4rem;
  flex:3 1 100px;
  max-width:120px;
  color:var(--color-charcoal);
}
.add-blog-form-input-wrapper {
  flex:5 1 140px;
  margin:.3rem;
}
.add-blog-form-input,.add-blog-form-textarea {
  border: 1px solid var(--color-charcoal);
  padding:.35rem .2rem;
  transition: all .15s ease;
  outline: none;
  border-radius: 1px;
  width:90%;
  max-width:320px;
}
.add-blog-form-input:focus,.add-blog-form-textarea {
  outline: none;
  box-shadow: 0 0 5px #ddd;
}

.add-blog-form-textarea-wrapper {
  flex:5 1 150px;
  margin:.3rem;
}
.add-blog-form-textarea {
  width:100%;
  height: 85px;
}

.add-blog-form-button {
  display: block;
  margin:.6rem .1rem .6rem 1.2rem;
  padding: .82rem 1.2rem;
  border: none;
  background: var(--color-primary);
  color:#fff;
  font-weight:700;
  border-radius:3px;
  transition: all .09s ease;
}
.add-blog-form-textarea:hover {
  opacity: .88;
}
.add-blog-form-button:focus {
  background: var(--color-333);
}