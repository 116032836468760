.admin-volunteer-title {
  text-align: center;
  font-size: 1.6rem;
  color: var(--color-charcoal);
  margin:.75rem .1rem;
}

.volunteer-block {
  padding: .85rem .2rem;
  box-shadow: 0 0 5px #ddd;
  margin:.55rem auto;
  color:var(--color-charcoal);
}
.volunteer-block-table {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: .5rem .2rem;
  margin:.55rem auto;
}
.volunteer-label {
  flex:1 1 100px;
  text-indent:.6rem;
  color: var(--color-charcoal);
}
.volunteer-message {
  flex:1 1 100px;
  text-indent:.5rem;
  color: grey;
}
.volunteer-read-status-wrapper {
  flex:1 1 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.volunteer-read-status {
  margin:.2rem;
  width:14px;
  height: 14px;
  border-radius:50%;
  background: var(--color-green);
  opacity: .85;
}




/*each feed back message*/
.admin-each-message-block {
  margin:.5rem .4rem;
  padding: .35rem;
}
.admin-each-message-block-flex {
  display: flex;
  
}
.each-message-block-title {
  margin:.4rem .5rem;
  color: var(--color-charcoal);
}
.each-message-block-value {
  color:grey;
  margin:.3rem;
}

.each-message-block-value-message {
  margin:.6rem .1rem;
  padding: .5rem;
  text-indent:.7rem;
  color:grey;
}
.each-message-back {
  margin:.4rem .1rem;
  padding: .4rem .9rem;
  border: none;
  background: var(--color-primary);
  color:#fff;
  font-weight:450;
  transition: all .1s ease;
}
.each-message-back:hover {
  opacity: .9;
}

.no-volunteer-yet {
  text-align: center;
  margin:.7rem .1rem;
  color: grey;
}