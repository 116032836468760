.partener-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.partener-welcome-img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  object-position: 0 12%;
  overflow: hidden;
}
.partener-welcome-content-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.partener-welcome-content {
  padding: 12px;
}
.partener-welcome-title-wrapper,
.partener-welcome-text-wrapper {
  overflow: hidden;
}
.partener-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 5px 2px;
  letter-spacing: 3px;
  animation: partenerwelcometitle 0.7s ease;
}
@keyframes partenerwelcometitle {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
.partener-welcome-text {
  color: #eee;
  text-align: center;
  font-weight: 850;
  font-size: 1.1rem;
  animation: partenerwelcometext 0.7s ease;
}
@keyframes partenerwelcometext {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

/*partener wrapper*/

.partener-wrapper {
  background: var(--color-background);
  padding: 0.8rem 0.3rem;
}
.partener {
  width: 98%;
  margin: 0.6rem auto;
  max-width: 800px;
  padding: 0.7rem 0.5rem;
  background: #fff;
  box-shadow: 0 5px 10px #ddd;
}

/*partener form title*/
.partener-form-title-wrapper {
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 0.3rem;
}
.partener-form-title {
  font-size: 2.4rem;
  font-weight: 700;
  text-indent: 0.2rem;
  color: var(--color-primary);
}

.partener-form {
  width: 98%;
  margin: 0.5rem auto;
  padding: 1rem 0.8rem 0.4rem 0.8rem;
}
.status {
}
.good-stat {
  text-align: center;
  margin: 0.3rem;
  transition: all 0.3s ease;
  color: var(--color-green);
}

.bad-stat {
  text-align: center;
  margin: 0.3rem;
  transition: all 0.3s ease;
  color: var(--color-secondary);
}
.partener-form-block {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.partener-form-label {
  flex: 3 1 200px;
  margin: 5px;
  color: var(--color-charcoal);
  font-weight: 410;
  padding: 0.3rem 0.3rem 0.3rem 0.6rem;
  text-indent: 0.5rem;
  font-weight: bold;
}
.partener-form-input-wrapper {
  flex: 5 1 250px;
  margin: 5px;
  padding: 0.1rem;
}
.partener-form-input {
  width: 100%;
  height: 45px;
  padding: 0.3rem 0.6rem;
  border: 1.2px solid #ddd;
  border-radius: 3px;
  transition: all 0.1s ease;
}
.partener-form-input:focus {
  outline: none;
  box-shadow: 0 1px 5px #ddd;
}
.partener-form ::placeholder {
  color: grey;
  padding: 0.2rem;
}

/*partener form radio*/
.partener-form-radio-block {
  display: flex;
}
.partener-form-radio-wrapper {
  margin: 0.3rem;
  display: flex;
}
.partener-form-radio {
  margin: 0.3rem;
}
.partener-form-radio-text {
  margin: 0.3rem;
  color: grey;
}

/*partener form submit*/
.partener-form-submit-wrapper {
  margin: 1rem 0.4rem 0.4rem 0.5rem;
  padding: 0.3rem 0.5rem;
}
.partener-form-submit {
  margin: 0.7rem 0.4rem 0.5rem 1rem;
  padding: 0.85rem 1.2rem;
  border: none;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  background: var(--color-primary);
  transition: all 0.1s ease;
  border-radius: 3px;
  box-shadow: 0 2px 10px #ddd;
  width: 150px;
  display: flex;
  justify-content: center;
}

.partener-invalid-input {
  margin: 0.2rem;
  padding: 0.3rem 0.5rem 0.5rem 0.9rem;
  color: var(--color-secondary);
  transition: all 0.2s ease;
}
.partener-form-submit:focus {
  background: var(--color-333);
}
.partener-button-text {
  display: inline-block;
  margin: 0.1rem;
}
.partener-button-icon {
  display: inline-block;
  display: inline-block;
  margin: auto 0.1rem;
  fill: #fff;
  width: 28px;
  height: 20px;
}
