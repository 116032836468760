/*one program*/
/*common*/
.one-program-detail-paragraph {
  margin:.2rem .3rem;
  padding: .3rem .5rem;
  color:#555;
  
}
.one-program-detail-wrapper {
  background:#eee;
  padding: .6rem;
}
.one-program-detail {
  width:99%;
  max-width:850px;
  margin:.8rem auto;
  padding:.7rem .5rem;
  background: #fff;
  box-shadow: 0 6px 8px #ddd;
}
.one-program-detail-topic {
  margin:.3rem .3rem .3rem .3rem;
  padding: .75rem .45rem;
}
.one-program-detail-topic-breadcrumbs {
  margin:.3rem .3rem .3rem .6rem;
  color: grey;
  padding: .1rem .1rem .1rem .3rem;
}
.one-program-breadcrumbs-title {
  display:inline-block;
  margin:.1rem .1rem;
  color:var(--color-primary);
}
.one-program-breadcrumbs-dot {
  display:inline;
  margin:0 .3rem;
  opacity: .7;
  color:var(--color-green);
}
.one-program-title {
  margin:.5rem .1rem;
  padding: .3rem .3rem .3rem .8rem;
  font-size: 1.8rem;
  color: var(--color-primary);
}
.one-program-description-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.one-program-description {
  flex:4 1 300px;
  margin:.7rem .3rem;
  padding: .1rem .4rem;
  color:grey;
  font-size: .8rem;
}
.one-program-description-img-wrapper {
  flex:3 1 250px;
  margin:5px ;
  border-radius:3px;
  
}
.one-program-description-img {
  width:100%;
  min-height: 160px;
  max-height: 210px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  filter:saturate(112%);
  border-radius: 3px;
  box-shadow: 0 6px 10px #ddd;
}

/*one program middle*/
.one-program-middle-defination-wrapper {
  display: flex;
  flex-flow: column nowrap;
}
.one-program-middle-description {
  margin:.2rem .4rem;
  color:grey;
  font-size:.8rem;
}
.one-program-middle-img-wrapper {
  width:92%;
  margin:.3rem auto;
  border-radius:3px;
  
}
.one-program-middle-img {
  width:100%;
  max-height:450px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 6px 10px #ddd;
}

