.donate-wrapper {

}
.donate {
  display: flex;
  justify-content: space-evenly;
  align-items: strech;
  margin:.4rem auto 0rem 0rem;
  padding: .1rem 0 0 0 ;
}
.donate-banner {
  flex:3;
  background:hsla(33, 100%, 53%, 1);
 background: linear-gradient(150deg, hsla(33, 100%, 53%, 1) 0%, hsla(58, 100%, 68%, 1) 100%);
  background-image: url("../../assets/img/donate/donate.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 0 10px 10px 0;
  filter:saturate(190%);
  filter:blur(2px);
}

.donate-content-wrapper {
  padding: .2rem .7rem;
  flex:5;
  
}
.donate-header {
  background: hsla(33, 100%, 53%, 1);
   padding: .8rem .7rem;
   margin: 0 .1rem;
   border-radius: 0 0 12px 12px;
}
.donate-header-title {
  margin:.4rem  .1rem;
  font-size: 1.3rem;
  color: #fff;
  
}
.donate-header-paragraph {
  font-size: .95rem;
  color: #fff;
  text-align: .3rem;
  padding: 2px .3rem;
  margin: .4rem .05rem;
}



.donate-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: .7rem 0;
  margin: .6rem .75rem .5rem 0;
  flex-flow: row wrap;
}
.donate-card-container {
  padding: 0 0 .6rem 0;
  border:1px solid #eee;
  box-shadow: 0 0 3px #eee;
  flex-basis:280px;
  flex-grow: 1;
  flex-shrink: 1;
  margin:.5rem .2rem;
}
.donate-card-image {
  height: 280px;
  width:100%;
  object-fit: contain;
  margin: 0 0 .3rem 0;
}

.donate-card-title {
   margin: .2rem;
   padding: .3rem .1rem;
   font-weight: 700;
   color: #333;
   font-size: 1.25rem;
   width: 100%;
}

.donate-card-number {
  margin: .5rem .2rem;
  color: grey;
  
}


@media only screen and (max-width:700px) {
  .donate-banner {
    display:none;
  }
  
  
  
}