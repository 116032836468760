/*all program welcome*/
.all-program-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.all-program-welcome-img {
  width:100%;
  height:350px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.all-program-welcome-content-wrapper {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-program-welcome-content {
  padding:12px;
}
.all-program-welcome-title-wrapper,.all-program-welcome-text-wrapper,.all-program-welcome-icon-wrapper {
  overflow: hidden;
}
.all-program-welcome-icon {
  display: block;
  width:4.5rem;
  height:3.8rem;
  fill:#fff;
  margin:.1rem auto;
  animation: allprogramwelcomeicon .7s ease;
}
@keyframes allprogramwelcomeicon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.all-program-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin:.3rem .2rem;
  letter-spacing: 1px;
  animation: allprogramwelcometitle .7s ease;
}
@keyframes allprogramwelcometitle {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform:translateY(0);
  }
}
.all-program-welcome-text {
  color:#fff;
  text-align: center;
  font-weight: 570;
  font-size: 1.13rem;
  animation: allprogramwelcometext .8s ease;
}
@keyframes allprogramwelcometext {
  0% {
    transform: translateY(-400%);
  }
  100% {
    transform:translateY(0);
  }
}




/*all programs*/ 

.all-program-wrapper {
   background: var(--color-background);
   margin:1rem 0;
}
.all-program {
    width:96%;
    margin:.8rem auto;
    max-width: 800px;
    padding: .5rem .8rem;
}
.all-program-big-title {
  text-align: center;
  font-size:3rem;
  font-weight:800;
  color: var(--color-primary);
  margin:.5rem auto;
  text-transform: capitalize;
  border-bottom: 2px solid var(--color-primary);
  padding: .4rem .4rem;
}
 
/*all program card*/
.all-program-card-wrapper {
  width:100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.all-program-card {
  flex:1 1 500px;
  width:98%;
  max-width: 700px;
  margin:1rem auto;
  padding:1.5rem 1rem;
  box-shadow: 0 4px 10px #ddd;
  
}
.all-program-card-icon-wrapper {
  width:100%;
  padding: .1rem;
}
.all-program-card-icon {
  display: block;
  margin:.2rem auto;
  width:6rem;
  height:5.5rem;
  fill:var(--color-primary);
}
.all-program-card-title {
  width:100%;
  margin:.8rem auto;
  text-align: center;
  text-shadow: -.2px -.2px 1px #ddd;
}
.all-program-card-description-wrapper{
  display: flex;
  width:100%;
  font-size: .9rem;
  padding:.25rem;
  color:grey;
}
.all-program-card-description {
  display: block;
  width:92%;
  margin:.7rem auto;
  padding:.3rem;
  color:grey;
  font-size:1.03rem;
}
.all-program-card-link-wrapper {
  display: flex;
}
.all-program-card-link {
  display: block;
  text-decoration: none;
  color: var(--color-primary);
  margin:.8rem .2rem .6rem .7rem;
  font-size: 1.1rem;
  text-indent: 10px;
}

/*all program full img*/
.all-program-full-img-wrapper {
  position: relative;
  width:100%;
}
.all-program-full-img {
  width:100%;
  min-height:300px;
  max-height: 450px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.all-program-full-img-overlay {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height: 100%;
  background: var(--home-page-gradient);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-program-full-img-overlay-content {
  margin:1rem .6rem .5rem 1rem;
  width:100%;
  max-width: 700px;
  
}
.all-program-full-img-overlay-title {
  font-size: 2.8rem;
  margin:.6rem .3rem;
  font-weight:700;
  padding: .3rem;
  color: #fff;
}
.all-program-full-img-overlay-description {
  margin:.5rem .3rem .3rem .5rem;
  font-size: .9rem;
  font-weight:420;
}
.all-program-full-img-overlay-button {
  display: block;
  padding: 1rem 1.2rem;
  font-size: 1.01rem;
  margin: 1rem 1rem .6rem auto;
  border: none;
  background: var(--color-primary);
  color: #fff;
  font-weight:500;
  border-radius:3px;
  box-shadow: 0 3px 8px rgba(0,0,0,.5);
  transition: all .15s ease;
}
.all-program-full-img-overlay-button:focus {
  background: var(--color-333);
}








@media only screen and (max-width:900px) {
/*all-program*/
.all-program-title {
  font-size: 2.5rem;
}

}

@media only screen and (max-width:780px) {
  /*all-program */
  .all-program-title {
  font-size: 2rem;
}



}


@media only screen and (max-width:600px) {
  /*all-program*/
  .all-program-title {
  font-size: 2rem;
}
.all-program-info {
  display: none;
}



}