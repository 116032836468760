.error-wrapper {
  
}
.error {
  width:98%;
  margin:.4rem auto;
  max-width: 800px;
  padding: 2rem .7rem ;
  display: flex;
  flex-flow: column;
  align-items:center;
  justify-content: center;
  min-height: 500px;
}
.error-big-text {
  font-size: 4.2rem;
  font-weight:900;
  color: var(--color-charcoal);
}
.error-title  {
  font-size: 4rem;
  font-weight:900;
}
.error-icon{
  display: inline-block;
  margin:.2rem .6rem;
  width:80px;
  height: 70px;
  fill:var(--color-333);
}
.error-message {
  margin:.5rem .2rem;
  padding: .6rem .7rem;
  color: var(--color-charcoal);
}
.error-button {
  display: block;
  margin:.5rem auto;
  padding: .8rem 1.2rem;
  background: var(--color-primary);
  color: #fff;
  font-size: 1.04rem;
  font-weight:700;
  border-radius:2px;
  box-shadow: 0 2px 8px #ddd;
  border: 2px solid var(--color-primary);
}


@media only screen and (max-width:780px) {
  .error-big-text {
    font-size: 3.3rem;
  }
}
@media only screen and (max-width:60px) {
  .error-big-text {
    font-size: 2.6rem;
  }
}