@import url("https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Lato&family=Oswald:wght@700&family=Roboto+Condensed&family=Ubuntu&display=swap");

button {
  cursor: pointer !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  scroll-behavior: smooth;
}
/*global*/
.anton {
  font-family: "Anton", sans-serif;
  letter-spacing: 2px;
}
.beba {
  font-family: "Bebas Neue", cursive;
}
.lato {
  font-family: "Lato", sans-serif;
}
.oswald {
  font-family: "Oswald", sans-serif;
}
.roboto {
  font-family: "Lato", sans-serif;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif;
}
/*_________*/
html {
  font-family: "Lato", Sans-Serif;
}
:root {
  margin: 0;
  padding: 0;
  --color-background: #fff;
  --height-xl: 450px;
  --height-lg: 450px;
  --height-md: 420px;
  --height-sm: 350px;
  --color-primary: #0047ab;
  --color-secondary: crimson;
  --color-menuhover: #eee;
  --color-boxshadow: #eee;
  --color-333: #333;
  --color-icon: black;
  --color-orange: orange;
  --color-charcoal: #36454f;
  --color-title: #28282b;
  --color-green: #00a36c;
  --footer-wrapper: 800px;
  --footer-box: 300px;
  --footer-icon-color: #ddd;
  --home-page-gradient: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.6) 25%,
    rgba(0, 0, 0, 0.15) 100%
  );
  --home-page-background: #0047ab;
  --sub-page-gradient: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  --sub-page-background: #0047ab;
}
.links {
  text-decoration: none;
  color: #333;
  transition: background 1s ease;
}
.mail-link {
  text-decoration: none;
  color: var(--color-primary);
}

.links:hover {
}
.links:active {
}
/*Nav bar*/
.navbar {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 1px 5px #eee;
  padding: 0.7rem 0.4rem;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 2px;
  flex: 1;
}
.logo {
  border-radius: 50%;
  width: 3rem;
  cursor: pointer;
}
.logo-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-text {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-charcoal);
  cursor: pointer;
}

.menu-items-wrapper {
  display: flex;
  flex: 2;
  justify-content: space-around;
  align-items: center;
  padding: 1px 2px 1px 5px;
}
.one-menu-item-wrapper {
  position: relative;
}
.icon {
  width: 20px;
  height: 20px;
  fill: var(--color-333);
}
.arrow-down-icon {
  width: 1rem;
  height: 0.6rem;
  transition: transform 0.2s ease;
}
.arrow-down-open {
  transform: rotate(-90deg);
  animation: transform 0.2s ease;
}
.menu-item {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: #333;
  padding: 10px;
  flex: 1 1 2.5rem;
  cursor: pointer;
}
.menu-item-text {
  margin: 1px 2px 1px 4px;
  font-weight: 500;
  font-size: 1.05em;
}

.navbar-button {
  padding: 0.65rem 1.25rem;
  border: 2px solid #eee;
  outline: none;
  background: var(--color-333);
  color: #fff;
  box-shadow: 0 0 5px #ddd;
  font-size: 1rem;
  transition: all 0.08s ease;
  border-radius: 2px;
  margin: 1px 5px 1px 1px;
  font-weight: 500;
}
.navbar-button:hover {
  opacity: 0.95;
}
.navbar-button:focus {
  background: var(--color-primary);
}
.drop-down {
  position: absolute;
  top: calc(100%);
  left: 10%;
  min-width: 195px;
  padding: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  transition: all 0.1s ease;
  opacity: 0;
  z-index: 20;
  background: #fff;
  color: #333;
}
.drop-down-invisible {
  visibility: hidden;
}
.animate-drop-down {
  visibility: visible;
  opacity: 1;
  transform: translateY(2px);
  transition: all 0.05s ease;
  animation: bigscreendropdownanimate 0.3s ease;
}
@keyframes bigscreendropdownanimate {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
.drop-down-link {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #333;
  flex: 1 1 2.5rem;
  padding: 0.5rem 0.05rem;
  width: 100%;
  transition: all 0.2s all;
  margin: 2px auto;
}
.drop-down-link-text {
  display: inline-block;
  margin: 2px 2px 2px 8px;
}
.drop-down-link:hover {
  opacity: 0.9;
}
.drop-down-link:focus {
  background: #ddd;
}

/*small Scrren Nav bar*/

.sm-navbar-wrapper {
  display: none;
}
.sm-navbar {
  position: relative;
}
.sm-navbar-menu {
  position: absolute;
  top: calc(100% + 0.3rem);
  right: 50%;
  padding: 0.8rem 0.5rem;
  width: 300px;
  z-index: 10;
  background: #333;
  color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
  transition: all 0.3s ease;
  border-radius: 3px;
}
.sm-navbar-menu-animate {
  visibility: visible;
  opacity: 1;
  transition: all 0.05s ease;
  animation: smscreendropdownanimate 0.3s ease;
}
@keyframes smscreendropdownanimate {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sm-navbar-button {
  padding: 0.7rem 1.3em;
  background: var(--color-333);
  border: 1px solid var(--color-333);
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  transition: all 0.08s ease;
  box-shadow: 0 0 4px #ddd;
}

.sm-navbar-button:hover {
  opacity: 0.95;
}
.sm-navbar-button:focus {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.sm-navbar-dropper {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 7px 2px;
  overflow: hidden;
  color: #fff;
  background: #333;
  color: #fff;
}
.sm-navbar-dropper:focus {
}
.sm-navbar-link {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0.5rem 0.5rem;
  text-decoration: none;
  color: #fff;
}
.sm-navbar-link:hover {
}
.sm-navbar-link:focus {
}
.sm-navbar-text {
  padding: 0 0 0 6px;
}

.sm-navbar-droping-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0.2rem 0.2rem 0.2rem 0.5rem;
}
.sm-drop-down-animate {
  animation: dropdownslide.4s ease;
}
@keyframes dropdownslide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.menu-icon {
  width: 2.3rem;
  transition: all 0.1s ease;
  padding: 1px;
}
.menu-icon:hover {
  opacity: 0.9;
}

/*welcome theme*/
.welcome-wrapper {
  width: 100%;
  position: relative;
  background: var(--color-primary);
  overflow: hidden;
}

.welcome-img {
  display: block;
  width: 100vw;
  max-height: 400px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}
.welcome-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 50, 0.03) 15%,
    rgba(0, 0, 0, 0.01) 100%
  );
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
}
.welcome-title {
  font-family: Oswald, Sans-Serif;
  font-size: 3rem;
  font-weight: 900;
  flex: 1;
  text-align: center;
}
.welcome-info {
  flex: 1;
  font-weight: 500;
}
.inspired-by {
  font-size: 2rem;
}
.verse {
  font-size: 1.2rem;
}
.quote {
  font-size: 3rem;
  display: inline;
  font-weight: 900;
  font-family: serif;
}

/*welcome 1 (home page"*/
.home-page {
  width: 100%;
  position: relative;
  background: var(--sub-page-background);
  padding: 0;
}
.home-page-img {
  width: 100%;
  height: var(--height-xl);
  max-height: 100vh;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  filter: saturate(115%);
}
.home-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--home-page-gradient);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-page-overlay-content {
  flex: 1 1 300px;
  max-width: 800px;
  padding: 15px 10px;
  margin: 2px 2px 2px 10px;
}
.home-page-title-wrapper {
  overflow: hidden;
}
.home-page-title {
  font-size: 3rem;
  font-weight: 900;
  transition: animation 3s ease;
  animation: homepagetitle 0.9s ease;
}
.home-page-title-itself {
  display: block;
}
.home-page-description-wrapper {
  overflow: hidden;
}
.home-page-description {
  text-indent: 5px;
  font-size: 1.1rem;
  margin: 5px;
  font-weight: 700;
  animation: homepagedescription 1.1s ease;
}
.home-page-button-wrapper {
  margin: 18px 5px 5px 5px;
  padding: 10px;
  overflow: hidden;
}
.home-page-first-button {
  padding: 1rem 1.1rem;
  font-size: 1.1rem;
  margin: 5px 10px;
  font-weight: 800;
  border-radius: 3px;
  background: #fff;
  color: #333;
  border: 2px solid #fff;
  transition: all 0.1s ease;
  animation: buttonanimation 0.7s ease;
  cursor: pointer;
}
.home-page-first-button:focus {
  background: #fff;
  color: var(--color-333);
  border: 2px solid #fff;
}
.home-page-second-button {
  padding: 1rem 1.1rem;
  font-size: 1.1rem;
  margin: 5px 10px;
  background: var(--color-primary);
  color: #fff;
  font-weight: 800;
  border: 2px solid var(--color-primary);
  border-radius: 3px;
  transition: all 0.1s ease;
  cursor: pointer;
  animation: buttonanimation 1s ease;
}
.home-page-second-button:focus {
  background: var(--color-333);
  border: 2px solid var(--color-333);
}

@keyframes homepagetitle {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes homepagedescription {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes buttonanimation {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}

/*card styles*/
.card-wrapper {
  display: flex;
  width: 96%;
  padding: 5px 0;
  box-shadow: 0 1px 3px var(--color-boxshadow);
  flex-flow: row wrap;
  margin: 10px auto;
}
.help-wrapper {
  position: relative;
  flex: 5 1 350px;
  max-height: 380px;
  overflow: hidden;
}
.help-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: #fff;
}
.help-img {
  width: 100%;
}
.help {
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 3px;
  padding: 8px 2px 6px 15px;
  color: hsl(0, 0%, 100%);
  display: inline-block;
}
.help-text {
  font-size: 1.2rem;
  font-weight: 390;
  flex: 1 1 400px;
  width: 100%;
}
/*card detail*/
.card-detail-wrapper {
  flex: 4 1 300px;
  margin: 5px 10px;
  border: 1px solid var(--color-boxshadow);
}
.kfgc-holistic {
  text-align: center;
  font-weight: 900;
  font-size: 1.6rem;
  padding: 5px 6px;
  margin: 5px auto;
}
.card-quote {
  color: var(--color-secondary);
}
.card-text {
  padding: 5px;
  font-weight: 398;
  font-size: 0.9rem;
}
.card-text b {
  font-weight: 650;
}
.card-button-holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 2px;
  margin: 5px auto;
}
.card-button {
  display: block;
  margin: 2px 6px;
  padding: 8px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s ease;
  background: transparent;
  font-family: serif;
  opacity: 0.85;
}
.card-button:focus {
}
.card-button:hover {
  box-shadow: 0 1px 2px var(--color-boxshadow);
}
.card-button:hover .card-button-icon {
  fill: #fff;
}
.card-readmore-icon {
  width: 50px;
  height: 20px;
  fill: var(--color-primary);
}
.card-document-icon {
  width: 50px;
  height: 20px;
  fill: var(--color-secondary);
}
.readmore-button {
  border: 1.5px solid var(--color-primary);
}
.readmore-button:hover {
  background: var(--color-primary);
  color: #fff;
}
.document-button {
  border: 1.5px solid var(--color-secondary);
}
.document-button:hover {
  background: var(--color-secondary);
  color: #fff;
}

/*what we do card 1*/
.what-we-do-dad {
  width: 100%;
  padding: 15px 10px;
  background: var(--color-background);
}
.what-we-do-wrapper {
  display: flex;
  width: 98%;
  margin: 10px auto;
  max-width: 900px;
  font-size: 1rem;
  flex-flow: row wrap;
}
.what-we-do {
  flex: 1 1 350px;
  padding: 12px 5px;
  margin: 5px 10px;
}
.what-we-do-title {
  width: 100%;
  font-family: Oswald, Sans-Serif;
  font-weight: 900;
  font-size: 3rem;
  margin: 5px 2px;
  color: var(--color-charcoal);
}
.what-we-do-itself {
  color: var(--color-primary);
  display: block;
}
.what-we-do-description {
  width: 100%;
  margin: 15px 5px;
  padding: 10px 5px;
  color: grey;
  font-size: 1.05rem;
}
.what-we-do-button {
  margin: 12px 5px 5px 20px;
  padding: 15px 25px;
  border: 1px solid var(--color-333);
  color: #fff;
  background: var(--color-333);
  border-radius: 2px;
  font-size: 1.1rem;
  font-weight: 800;
  transition: all 0.2s ease;
}
.what-we-do-button:hover {
  opacity: 0.9;
  box-shadow: 0 0 2px #333;
}
.what-we-do-button:focus {
  background: var(--color-primary);
}
.what-we-do-card-container {
  flex: 1 1 400px;
}
.what-we-do-card-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 100%;
}
.what-we-do-card {
  flex: 1 1 180px;
  max-width: 300px;
  margin: 10px 5px;
}
.what-we-do-card-icon-wrapper {
  text-align: center;
}
.what-we-do-card-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 8px auto;
  fill: var(--color-primary);
}
.what-we-do-card-title {
  width: 90%;
  text-align: center;
  margin: 5px auto;
  padding: 5px;
  color: var(--color-333);
  font-size: 1rem;
}
.what-we-do-card-description {
  width: 100%;
  font-size: 0.98rem;
  color: grey;
  padding: 10px;
}

/*support now */
.support-now-wrapper {
  background: var(--color-background);
  padding: 1rem 0.5rem;
}
.support-now {
  width: 98%;
  max-width: 900px;
  margin: 1rem auto;
}
.support-now-img {
  box-shadow: -3px 3px 12px #ddd;
  border-radius: 2px;
  min-height: 270px;
  max-height: 500px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  filter: saturate(110%);
}
.support-now-content {
  width: 100%;
  margin: 0.8rem auto;
  padding: 0.3rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.support-now-title {
  flex: 1 1 300px;
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--color-primary);
  margin: 0.5rem 0.2rem;
}
.support-now-button-holder {
  flex: 1 1 300px;
  margin: 0.7rem 0.2rem;
  padding: 0.3rem 0.2rem 0.3rem 0.9rem;
  display: flex;
  justify-content: flex-end;
}
.support-now-button {
  border: none;
  padding: 0.75rem 1.6rem;
  margin: 0.3rem 0.5rem;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 9px #ddd;
  transition: all 0.1s ease;
  letter-spacing: 0.5px;
  font-size: 1.02rem;
  font-weight: 450;
}
.support-now-button:hover {
  opacity: 0.9;
}

.support-now-partener-button {
  background: var(--color-secondary);
  border: 2px solid var(--color-secondary);
}
.support-now-support-button {
  background: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
.support-now-partener-button:focus {
  background: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
}
.support-now-support-button:focus {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
}

/*feature */
.feature-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.feature {
  flex: 1 1 450px;
  width: 80%;
  max-width: 800px;
  position: relative;
  padding: 5px;
}
.feature-itself {
  padding: 6px 4px 6px 10px;
  font-size: 0.95rem;
  color: grey;
  font-weight: 290;
}
.feature-img {
  width: 90%;
}
.feature-content {
  background: #333;
  color: white;
  position: absolute;
  top: -10px;
  left: 50%;
  width: 45%;
  padding: 10px 8px;
}
.feature-title {
  padding: 2px 2px 2px 5px;
  font-weight: 700;
  color: var(--color-secondary);
  letter-spacing: 2px;
}
.feature-text {
  margin: 2px 3px;
  padding: 3px 4px;
  color: #eee;
}
.feature-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: grey;
}
.feature-link {
  flex: 1;
}
.feature-date {
  flex: 3;
  text-align: right;
}
/*feature card*/
.feature-card-wrapper {
  display: flex;
  padding: 10px 5px;
  margin: 5px auto;
  justify-content: space-around;
  align-items: center;
  width: 94%;
  flex-flow: row wrap;
}
.feature-card {
  flex: 1 1 200px;
  padding: 10px 5px;
  max-width: 350px;
  margin: 8px 3px;
}
.feature-card-img {
  width: 100%;
}

.feature-card-title {
  padding: 4px 3px 2px 8px;
  font-weight: 450;
}
.feature-card-text {
  margin: 4px 3px;
  padding: 4px 5px;
  font-size: 0.9rem;
  font-weight: 390;
}

/*iframe */
.iframe-wrapper {
  position: relative;
}
.iframe-img {
  width: 100%;
  min-height: 220px;
  max-height: 340px;
  object-fit: cover;
  object-position: 0 56%;
  overflow: hidden;
}
.iframe-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4));
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-overlay-content {
}
.iframe-overlay-title {
  font-size: 2.7rem;
  text-align: center;
  font-weight: 800;
}
.iframe-overlay-icon {
  display: block;
  margin: 10px auto;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  fill: var(--color-primary);
  opacity: 0.9;
  outline-offset: 2px;
  outline: 2px solid grey;
  transition: all 0.1s ease;
  cursor: pointer;
}
.iframe-overlay-icon:hover {
  opacity: 0.85;
  outline-offset: 3px;
}

/*iframe popup*/
.iframe-video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
}
.iframe-video-content {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  flex: 1;
  max-width: 800px;
}
.iframe-video-wrapper-animate {
  animation: iframepopup 0.3s ease;
}
@keyframes iframepopup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.iframe-close-icon {
  width: 35px;
  height: 35px;
  fill: #fff;
  outline-offset: 2px;
  outline: 1px solid #fff;
  margin: 0.1rem 0.6rem 0.7rem auto;
  border-radius: 50%;
  transition: all 0.15s ease;
}
.iframe-video {
  width: 95%;
  margin: 0.4rem auto;
  max-width: 600px;
  height: 300px;
}

/*events*/
.event-wrapper {
  width: 100%;
  margin: 10px auto;
  margin-bottom: 30px;
  background: #fff;
  color: #333;
}
.event-sub-wrapper {
  width: 90%;
  max-width: 900px;
  margin: 5px auto;
}
.event-card {
  margin: 2px 5px;
  position: relative;
}
.event-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}
.event-itself {
  font-weight: 600;
  margin: 6px;
  padding: 5px;
}
.event-seemore-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
  transition: all 0.2s ease;
  font-family: serif;
  background: transparent;
}
.event-seemore-button:hover {
  transform: scale(1.02);
  background: var(--color-secondary);
  color: #fff;
}

.event-img {
  width: 100%;
}
.event-card-title {
  text-align: center;
  font-weight: 500;
  padding: 5px;
  margin: 4px;
}
.event-card-text {
  padding: 6px 8px;
  margin: 5px;
  font-weight: 440;
}
.event-card-button {
  display: block;
  border-radius: 4px;
  width: 90%;
  margin: 8px auto;
  background: var(--color-green);
  color: #fff;
  padding: 12px 10px;
  border: 1px solid transparent;
  transition: all 0.5s ease;
  font-weight: 500;
  font-family: serif;
}
.event-card-button:hover {
  background: transparent;
  border: 1px solid var(--color-green);
  color: var(--color-green);
  border-radius: 6px;
}
.event-card-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /*  background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5));*/
  background: #333;
  color: #fff;
  text-align: center;
  padding: 10px 8px;
  /*display:none;*/
  transform: translateY(-10px);
  transition: all 0.3s ease;
}
/*
.event-card:hover .event-card-popup {
  transform:translateY(0) ;
  display: block;
}
*/
.event-toggler-next,
.event-toggler-prev {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: opacity 0.1s ease;
  fill: var(--color-green);
}
.event-toggler-next:hover,
.event-toggler-prev:hover {
  opacity: 0.95;
}

/*purpose */

.purpose-wrapper {
  margin: 18px auto;
  width: 100%;
}
.purpose-list-wrapper {
  position: relative;
  width: 96%;
  margin: 10px auto;
  max-width: 1000px;
}
.purpose-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.purpose-content {
  display: flex;
  justify-content: space-around;
}
.purpose-card {
  flex: 1 1 300px;
  max-width: 500px;
  margin: 0.3rem 0.6rem;
  background: #fff;
  padding: 0.3rem 0.1rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}
.purpose-card-icon {
  width: 180px;
  height: 120px;
  fill: var(--color-primary);
  margin: 10px auto;
}
.purpose-card-img {
  width: 7rem;
  margin: 10px auto;
}
.purpose-card-title {
  width: 100%;
  margin: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
}
.purpose-card-description {
  width: 96%;
  padding: 0.8rem 0.15rem;
  margin: 0.05rem auto;
  font-size: 0.96rem;
  color: var(--color-charcoal);
}

/*program */
.program-wrapper {
}

.program-title {
  width: 100%;
  text-align: center;
  font-weight: 900;
  font-size: 2rem;
  padding: 15px 8px;
}
.program-itself {
  display: inline-block;
  padding: 5px 6px;
  background: var(--color-secondary);
  color: #fff;
}
.program-card-wrapper {
  width: 98%;
  margin: 10px auto;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}
.program-card {
  flex: 1 1 350px;
  margin: 8px 5px;
  max-width: 500px;
  position: relative;
  overflow: hidden;
}
.program-card-img {
  width: 100%;
  height: 220px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}
.program-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.program-overlay-title {
  font-weight: 800;
  font-size: 2rem;
  padding: 5px;
}
.program-overlay-quote {
  padding: 5px 5px 5px 10px;
  font-weight: 600;
  font-style: italic;
  font-size: 1.1rem;
}
.program-button {
  display: block;
  margin: 2px 6px;
  padding: 8px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background: var(--color-green);
  color: #fff;
  font-weight: 450;
  font-size: 1rem;
  border: none;
  position: absolute;
  bottom: 5%;
  right: 0;
  transform: translateX(110%);
  transition: all 0.2s ease;
}
.program-card:hover .program-button {
  transform: translateX(0);
}
.program-button:hover {
  opacity: 0.95;
  box-shadow: 0 0 2px #eee;
}
.program-readmore-icon {
  width: 50px;
  height: 20px;
}

/*out come*/
.outcome-wrapper {
  background: #fff;
  margin: 0.4rem 0.1rem;
  padding: 1.5rem 0.1rem;
  background: var(--color-green);
}
.outcome {
  width: 98%;
  max-width: 800px;
  margin: 0.6rem auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-self: center;
  padding: 0.8rem 0.2rem;
}
.outcome-card-wrapper {
  flex: 1 1 320px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.2rem;
}
.outcome-card {
  flex: 1 1 170px;
  margin: 0.4rem 0.1rem;
  padding: 2rem 0.4;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.outcome-card-number {
  font-size: 2.5rem;
  font-weight: 900;
  color: #fff;
}
.outcome-plus {
  display: inline-block;
  color: var(--color-primary);
  font-weight: 900;
}
.outcome-card-quote {
  color: #fff;
  margin: 0.3rem 0.1rem;
  font-weight: 399;
}

/*subscribe*/
.subscribe-wrapper {
  width: 100%;
  padding: 15px 5px;
  margin: 10px auto 0 auto;
  background: var(--color-primary);
  color: #fff;
}
.subscribe {
  width: 96%;
  margin: 10px auto;
  max-width: 900px;
}
.email-icon {
  width: 180px;
  height: 110px;
  fill: #fff;
  margin: 5px 5px 10px;
}
.be-the-first {
  font-size: 1.8rem;
  font-weight: 700;
  color: #eee;
  text-indent: 5px;
}
.be-the-first-description {
  margin: 8px 2px;
  padding: 10px 8px;
  color: #eee;
  font-size: 15px;
}
.subscribe-form-title {
  width: 100%;
  text-align: center;
  font-weight: 900;
  font-size: 2rem;
  padding: 15px 8px;
}
.subscribe-itself {
  display: inline-block;
  padding: 5px 6px;
  background: var(--color-secondary);
  color: #fff;
}
.subscribe-message {
  width: 100%;
  text-align: center;
  margin: 0.2rem auto;
  padding: 0.15rem;
  font-weight: 410;
}
.subscribe-form {
  padding: 10px 5px;
  margin: 2px 8px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-self: center;
}
.subscribe-form > label {
  padding: 8px 2px;
  margin: 8px auto;
  font-size: 1.1rem;
  font-weight: 750;
  flex: 1 1 220px;
  text-align: center;
}
.subscribe-form > label > input {
  display: inline-block;
}
.subscribe-form-name,
.subscribe-form-email {
  box-shadow: none;
  border: none;
  background: rgba(20, 20, 20, 0.28);
  padding: 12px;
  font-family: Lato, Sans-Serif;
  border-radius: 3px;
  transition: all 0.2s ease;
  color: #fff;
  width: 240px;
}
.subscribe-form-name:focus,
.subscribe-form-email:focus {
  outline: none;
  box-shadow: none;
  opacity: 0.96;
}

.subscribe-form-submit {
  margin: 12px auto;
  padding: 10px 20px;
  border: 2px solid #eee;
  border-radius: 2px;
  background: transparent;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 480;
  transition: all 0.1s ease;
  width: 180px;
  cursor: pointer;
  font-weight: 680;
}
.subscribe-form-submit:hover {
  opacity: 0.88;
}
.subscribe-form-submit:focus {
  transform: scale(1.05);
}
.subscribe-form-name::placeholder,
.subscribe-form-email::placeholder {
  color: #fff;
  font-weight: 480;
  padding-left: 6px;
}

/*social icons */
.social-icons {
}

/*footer*/
.footer-wrapper {
  width: 100%;
  background: rgba(255, 255, 250, 0.7);
  color: grey;
}
.footer {
  width: 98%;
  padding: 8px 5px;
  margin: 5px auto;
  max-width: var(--footer-wrapper);
  display: flex;
  flex-flow: row wrap;
}

/*footer company*/
.footer-company {
  padding: 10px 10px 10px 10px;
  flex: 1 1 var(--footer-box);
}

.footer-company-we {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.footer-company-logo {
  width: 60px;
  border-radius: 50%;
  margin: 2px 5px;
}
.footer-company-title {
  font-size: 1.2rem;
  font-weight: 900;
  color: var(--color-charcoal);
  font-family: "oswald", sans-serif;
}
.footer-company-copyright {
  color: var(--color-charcoal);
  font-size: 0.9rem;
  font-weight: 399;
}
.footer-company-info {
  margin: 10px 2px;
  padding: 5px;
}
.footer-company-info-item {
  color: grey;
  margin: 8px 5px;
}
.footer-company-item-color {
  font-weight: 399;
  color: var(--color-primary);
  display: inline-block;
  margin: 2px 2px 2px 6px;
}
.footer-icon {
  width: 20px;
  height: 20px;
  margin: 6px 10px;
  fill: var(--color-primary);
}

/*footer about us*/
.footer-aboutus-wrapper {
  flex: 1 1 var(--footer-box);
  margin: 5px;
}
.footer-aboutus-title {
  color: #333;
  margin: 10px 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer-aboutus-link-wrapper {
  margin: 3px;
  padding: 2px 2px 2px 6px;
}
.footer-aboutus-link {
  color: grey;
  text-decoration: none;
}

/*footer-get-involve*/
.footer-get-involve-wrapper {
  padding: 10px 8px;
  margin: 5px;
  flex: 1 1 var(--footer-box);
}
.footer-get-involve {
  color: var(--color-charcoal);
  font-size: 1.4rem;
}
.footer-get-involve-button {
  margin: 0.7rem 0.3rem 0.3rem 1rem;
  padding: 0.85rem 1.05rem;
  border-radius: 3px;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
  transition: all 0.07s ease;
  font-weight: 600;
}
.footer-get-involve-button:hover {
  opacity: 0.9;
  box-shadow: 0 0 1px #333;
}
.footer-get-involve-button:focus {
  background: var(--color-333);
  border: 2px solid var(--color-333);
}

.footer-community-wrapper {
  margin: 10px 5px;
  padding: 5px;
}
.footer-join-community {
  font-size: 1.1rem;
  font-weight: 900;
  color: var(--color-charcoal);
  padding: 5px 10px;
}

.footer-top-right {
  flex: 6 1 300px;
  margin: 0 0 0 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.footer-col {
  flex: 1 1 180px;
  max-width: 300px;
  margin: 10px 5px;
}
.footer-quick-link-title {
  font-size: 1.1rem;
  padding: 2px 2px 2px 10px;
  color: grey;
  font-weight: 800;
}
.footer-quick-link {
  display: block;
  font-weight: 399;
  text-decoration: none;
  color: black;
  padding: 2px 1px 2px 6px;
  margin: 2px 5px;
}

/*footer quick links*/
.footer-quick-link-wrapper {
  flex: 1 1 var(--footer-box);
  margin: 5px;
  display: flex;
  flex-flow: row wrap;
}
.footer-quick-link-group {
  flex: 1 1 150px;
  margin: 5px 5px;
}
.footer-quick-link-title {
  color: #333;
  margin: 10px 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer-quick-link-link-wrapper {
  margin: 3px;
  padding: 2px 2px 2px 6px;
}
.footer-quick-link-link {
  color: grey;
  text-decoration: none;
}
/*footer programs*/
.footer-program-wrapper {
  flex: 1 1 var(--footer-box);
  margin: 5px;
  display: flex;
  flex-flow: row wrap;
}
.footer-program-group {
  flex: 1 1 150px;
  margin: 5px 5px;
}
.footer-program-title {
  color: #333;
  margin: 10px 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer-program-link-wrapper {
  margin: 3px;
  padding: 2px 2px 2px 6px;
}
.footer-program-link {
  color: grey;
  text-decoration: none;
}

/*footer bank info*/
.footer-bank-wrapper {
  flex: 1 1 var(--footer-box);
  margin: 5px;
  display: flex;
  flex-flow: row wrap;
}
.footer-bank-group {
  flex: 1 1 150px;
  margin: 5px 5px;
}
.footer-bank-title {
  color: #333;
  margin: 10px 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer-bank-bank-info {
  font-weight: 500;
  padding: 5px;
}

.footer-one-bank {
  margin: 3px;
  padding: 2px 2px 2px 6px;
}
.footer-bank-link {
  color: grey;
  text-decoration: none;
}
.footer-bank-online-support {
  margin: 8px;
}
.footer-bank-online-support-button {
  margin: 0.7rem 0.1rem 0.1rem 0.7rem;
  padding: 0.95rem 1.1rem;
  border: 1px solid var(--color-primary);
  color: #fff;
  font-weight: 600;
  background: var(--color-primary);
  border-radius: 3px;
  box-shadow: 0 0 3px #ddd;
  transition: all 0.07s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.support-button-text {
  display: inline-block;
  margin: 0.1rem 0.2rem;
}
.support-button-icon {
  display: inline-block;
  display: inline-block;
  margin: auto 0.1rem;
  fill: #fff;
  width: 36px;
  height: 27px;
}
.footer-bank-online-support-button:hover {
  opacity: 0.9;
}
.footer-bank-online-support-button:focus {
  background: var(--color-333);
  border: 1px solid var(--color-333);
}

/*footer copyright*/
.copyright-wrapper {
  border-top: 1px solid #ddd;
}
.copyright {
  width: 98%;
  max-width: 800px;
  margin: 0.5rem auto;
  padding: 0.5rem 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-text {
  padding: 0.1rem;
  margin: 0.3rem 0.1rem;
}
.copyright-symbol {
  font-size: 1.1rem;
  margin: 0.05rem 0.1rem;
}

@media only screen and (max-width: 900px) {
  .menu-item-text {
    font-size: 0.75rem;
  }

  .drop-down {
    width: 140px;
  }

  /*welcome*/
  .welcome-title {
    font-size: 2.5rem;
  }
  .inspired-by {
    font-size: 1.8rem;
  }
  .verse {
    font-size: 1.1rem;
  }
  .qoute {
    font-size: 2.3rem;
  }

  /*welcome 1 home page*/
  .home-page-img {
    height: var(--height-lg);
  }
}

@media only screen and (max-width: 780px) {
  /*menus exchange*/
  .menu-items-wrapper {
    display: none;
  }

  .sm-navbar-wrapper {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    align-items: center;
    padding: 2px 25px 2px 2px;
  }
  .sm-navbar-wrapper > * {
    margin: 2px 8px;
  }
  .icon,
  .arrow-down-icon {
    fill: #fff;
  }

  /*welcome */
  .welcome-title {
    font-size: 2rem;
  }
  .inspired-by {
    font-size: 1.6rem;
  }
  .verse {
    font-size: 1rem;
  }
  .qoute {
    font-size: 1.8rem;
  }

  /*welcome 1 home page*/
  .home-page-img {
    height: var(--height-md);
  }
  .home-page-title {
    font-size: 2.8rem;
  }

  /*card*/
  .card-wrapper {
    flex-flow: column-reverse wrap;
  }
  .help-wrapper {
    max-height: 400px;
  }
  .card-detail-wrapper {
    margin: 15px 10px;
  }
  /*what we do*/
  .what-we-do-card-description {
    font-size: 0.9rem;
  }

  /*feature */
  .feature-img {
    width: 100%;
  }
  .feature-content {
    top: 60%;
    left: 20%;
    width: 78%;
  }
  .feature-card-wrapper {
    margin: 30px auto;
  }
  /*iframe*/
  .iframe-overlay-title {
    font-size: 2.5rem;
  }

  /*purpose*/
  .purpose-layout {
    display: none;
  }
  .purpose-content {
    display: block;
  }
  .purpose-card {
    max-width: 100%;
  }
  .purpose-card-img {
    width: 120px;
    margin: 5px;
    text-align: left;
  }
  .purpose-card-title {
    text-align: left;
    text-indent: 12px;
    font-size: 1.2rem;
  }
  .purpose-card-description {
    padding: 5px;
  }
  /*footer*/
  .footer-top-right {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  /*sm navbar padding*/
  .sm-navbar-wrapper {
    padding: 2px 10px 2px 2px;
  }
  .sm-navbar-wrapper > * {
    margin: 2px 8px;
  }
  /*welcome*/
  .welcome-title {
    font-size: 2rem;
  }
  .welcome-info {
    display: none;
  }
  .inspired-by {
    font-size: 1.1rem;
  }
  .james {
    display: block;
    text-align: center;
  }
  .verse {
    font-size: 0.8rem;
  }
  .quote {
    font-size: 1.8rem;
  }
  /*welcome 1 home page*/
  .home-page-img {
    height: var(--height-sm);
  }
  .home-page-title {
    font-size: 2.5rem;
  }

  /*card*/
  .card-wrapper {
    width: 100%;
    flex-flow: column-reverse wrap;
  }
  .card-detail-wrapper {
    margin: 15px 10px;
  }
  .help-wrapper {
    max-height: 250px;
  }
  .help {
    font-size: 1.5rem;
  }
  .help-text {
    font-size: 1rem;
  }

  /*feature */
  .feature-img {
    width: 100%;
  }

  /*iframe video*/
  .iframe-video {
    height: 220px;
  }
  /*program*/

  .program-overlay-title {
    font-size: 2rem;
  }

  /*subscribe*/
  .subscribe-form-name,
  .subscribe-form-email {
    width: 96%;
    margin: 10px auto;
  }
  .subscribe-form-submit {
    width: 96%;
    margin: 10px auto;
  }

  /*sm screen navbar icon and button*/
}

@media only screen and (max-width: 460px) {
  :root {
    font-size: 14px;
  }

  /*iframe-video*/
  .iframe-video {
    height: 180px;
  }
}
@media only screen and (max-width: 350px) {
  :root {
    font-size: 9px;
  }
  /*sm nav bar*/
  .sm-navbar-menu {
    width: 200px;
  }

  /*iframe-video*/
  .iframe-video {
    height: 120px;
  }
  /*sm nav button*/
  .sm-navbar-button {
    padding: 0.6rem 0.75rem;
  }
}

@media only screen and (max-width: 260px) {
  :root {
    font-size: 7px;
  }
  /*sm nav bar*/
  .sm-navbar-menu {
    width: 150px;
  }
}
