/*common skeleton */
.card {
  list-style: none;
  margin: 0.5rem 0.1rem;
}
.blog-skeleton-wrapper {
  width: 96%;
  margin: 10px auto;
  max-width: 700px;
  padding: 12px 10px;
}
.blog-skeleton-list {
  display: flex;
  justify-content: space-around;
}
.blog-skeleton-card {
  flex: 1 1 250px;
  max-width: 350px;
  margin: 10px 8px;
}

.gallery-skeleton-card {
  margin: 8px;
  flex: 1 1 150px;
  max-width: 250px;
}

/*galler welcome*/
.gallery-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.gallery-welcome-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: 10% 10%;
  overflow: hidden;
}
.gallery-welcome-content-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-welcome-content {
  padding: 12px;
}
.gallery-welcome-title-wrapper,
.gallery-welcome-text-wrapper {
  overflow: hidden;
}
.gallery-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 5px 2px;
  letter-spacing: 1px;
  animation: gallerytitle 0.8s ease;
}
@keyframes gallerytitle {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
.gallery-welcome-text {
  color: #eee;
  text-align: center;
  font-weight: 550;
  font-size: 1.1rem;
  animation: gallerytext 0.8s ease;
}
@keyframes gallerytext {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
.gallery-welcome-button {
  border: none;
  background: var(--color-primary);
  font-size: 1.2rem;
  font-weight:900;
  padding:14px 35px;
  border-radius:3px;
  color:#fff;
  display: block;
  margin:12px auto;
  letter-spacing:1px;
  transition: all .05s ease;
}
.gallery-welcome-button:hover {
  transform: scale(1.009);
}
.gallery-welcome-button:focus {
  background: var(--color-333);
}
*/

/*galler-wrapper*/
.galler-wrapper {
  background: #ddd;
  padding: 10px;
}
.gallery-flex {
  background: #fff;
  width: 96%;
  margin: 10px auto;
  max-width: 800px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.7rem 0.5rem;
}
.gallery-card {
  margin: 8px;
  flex: 1 1 150px;
  max-width: 250px;
  position: relative;
  overflow: hidden;
  border-radius: 1px;
}
.gallery-overlay {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 99.2%;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
}
.gallery-overlay-icon-wrapper {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline-offset: 5px;
  outline: 2px dashed #fff;
  transition: all 0.1s ease;
}
.gallery-overlay-icon {
  width: 30px;
  height: 30px;
  fill: #fff;
  transition: all 0.15s ease;
  padding: 8px;
  cursor: pointer;
}
.gallery-overlay-icon-wrapper:hover {
  outline: 2px dashed var(--color-primary);
}
.gallery-overlay-icon:hover {
  fill: var(--color-primary);
  transform: scale(1.2);
}
.gallery-card:hover .gallery-overlay {
  visibility: visible;
}
.gallery-card:hover .gallery-card-img {
  transform: scale(1.3) rotate(10deg);
}

.gallery-card-img {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  object-position: 45%;
  overflow: hidden;
  filter: saturate(115%);
  border-radius: 1px;
  box-shadow: 0 2px 10px #eee;
  transition: all 0.65s ease;
  cursor: pointer;
}

/*gallery popup */
.gallery-popup-wrapper {
  z-index: 40;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.78);
  animation: popupwrapperanimation 0.1s ease;
}
@keyframes popupwrapperanimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gallery-popup {
  padding: 0.2rem 0.3rem;
  margin: 0 auto;
  width: 90%;
  max-width: 600px;
  animation: popupanimation 0.2s ease;
}
@keyframes test2 {
  0% {
    visibility: hidden;
    transform: scale(0.7);
  }
  100% {
    visibility: visible;
    transform: scale(1);
  }
}
.gallery-popup-killer {
  margin: 0.2rem auto 1rem auto;
  display: flex;
}
.gallery-popup-killer-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: translate(-15px, 30px);
  fill: #fff;
  outline-offset: 6px;
  outline: 2px solid #fff;
  margin: 0.1rem 0.6rem 0.3rem auto;
  border-radius: 50%;
  transition: all 0.15s ease;
  z-index: 999;
}
.gallery-popup-killer-icon:focus {
  transform: scale(0.95);
}
.gallery-popup-img {
  width: 100%;
  max-width: 500px;
  object-fit: contain;
  border-radius: 3px;
  filter: saturate(120%);
  max-height: 95vh;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

/*album*/
.album-wrapper {
  margin: 0.5rem;
}
.album {
  width: 98%;
  max-width: 800px;
  margin: 0.5rem auto;
  padding: 0.4rem 0.3rem;
  display: flex;
  flex-flow: row wrap;
}
.album-title {
  width: 100%;
  text-indent: 0.5rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-charcoal);
}

.album-card {
  flex: 1 1 250px;
  max-width: 420px;
  margin: 0.3rem;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 3px #eee;
}
.album-card-img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  object-position: 45%;
  overflow: hidden;
  filter: saturate(118%);
  border-radius: 3px;
}

.album-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.28);
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.album-card-overlay-content {
  margin: 0.1rem 0.1rem 0.8rem 0.4rem;
}
.album-card-overlay-title {
  font-size: 1.6rem;
  font-weight: 600;
}
.album-card-overlay-text {
  margin: 0.2rem;
}
/*album gallery some*/
.album-gallery-topic {
  width: 98%;
  margin: 0.4rem auto;
  max-width: 800px;
  padding: 0.5rem 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.album-gallery-title {
  flex: 1;
  font-size: 2.2rem;
  text-indent: 0.5rem;
  font-weight: 900;
  color: var(--color-primary);
}
.album-gallery-back-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.album-gallery-back {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.05rem;
  display: inline-block;
  border: 2px solid #eee;
  border-radius: 3px;
  padding: 0.3rem 0.4rem;
}

/*blog welcome*/
.blog-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.blog-welcome-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  overflow: hidden;
}
.blog-welcome-content-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-welcome-content {
  padding: 12px;
}
.blog-welcome-title-wrapper,
.blog-welcome-text-wrapper {
  overflow: hidden;
}
.blog-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 5px 2px;
  letter-spacing: 1px;
  animation: blogwelcometitle 0.7s ease;
}
@keyframes blogwelcometitle {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}
.blog-welcome-text {
  color: #eee;
  text-align: center;
  font-weight: 550;
  font-size: 1.1rem;
  animation: blogwelcometext 0.8s ease;
}
@keyframes blogwelcometext {
  0% {
    transform: translateY(-400%);
  }
  100% {
    transform: translateY(0);
  }
}

/*blog wrapper*/
.blog-wrapper {
  background: var(--color-background);
}
.blog-flex {
  width: 96%;
  margin: 10px auto;
  max-width: 700px;
  padding: 12px 10px;
  display: flex;
  flex-flow: row wrap;
}

.blog-card {
  flex: 1 1 250px;
  max-width: 350px;
  margin: 10px 8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px #ddd;
  border: 1px solid #ddd;
}

.blog-card-img {
  width: 100%;
  height: 150px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.blog-card-content {
  padding: 0.4rem 0.2rem;
}
.blog-card-title {
  width: 100%;
  text-align: center;
  font-size: 1.02rem;
  color: var(--color-charcoal);
  margin: 5px;
  font-weight: 600;
}
.blog-card-description {
  color: grey;
  font-size: 0.9rem;
  width: 100%;
  padding: 0.7rem 0.3rem;
}
.blog-card-link {
  text-decoration: none;
  display: inline-block;
  margin: 10px 5px 5px 12px;
  color: var(--color-primary);
  font-size: 1rem;
  transition: all 0.2s ease;
}

/*blog detail*/

.blog-detail-wrapper {
  padding: 10px 5px;
  background: var(--color-background);
}
.blog-detail {
  width: 96%;
  margin: 15px auto;
  max-width: 680px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px #ddd;
}
.blog-detail-img {
  width: 100%;
  height: 300px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.blog-detail-content {
  margin: 2px 0 5px 0;
  padding: 8px 5px;
  width: 100%;
}
.blog-detail-date-icon {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  fill: var(--color-primary);
  transform: translateY(4px);
}
.blog-detail-date {
  width: 100%;
  text-align: right;
  margin: 5px 0;
  padding: 3px 10px 3px 3px;
}
.blog-detail-title {
  text-indent: 0.1rem;
  font-size: 1.3rem;
  color: var(--color-charcoal);
  font-weight: 600;
  width: 100%;
}
.blog-detail-description {
  width: 100%;
  font-size: 0.8rem;
  color: grey;
  padding: 12px;
  margin: 10px;
}
.blog-detail-button {
  display: block;
  width: 80px;
  border: none;
  background: var(--color-primary);
  color: #fff;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 3px;
  transition: all 0.1s ease;
  margin: 10px 5px 12px 15px;
}
.blog-detail-button:focus {
  background: var(--color-333);
}

/*event welcome*/
.event-page-welcome {
  position: relative;
  background: var(--sub-page-background);
}
.event-page-welcome-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}
.event-page-welcome-content-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: var(--sub-page-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-page-welcome-content {
  padding: 12px;
}
.event-page-welcome-title-wrapper,
.event-page-welcome-text-wrapper {
  overflow: hidden;
}
.event-page-welcome-title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 5px 2px;
  letter-spacing: 1px;
  animation: eventwelcometitle 0.7s ease;
}
@keyframes eventwelcometitle {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
.event-page-welcome-text {
  color: #eee;
  text-align: center;
  font-weight: 550;
  font-size: 1.1rem;
  animation: eventwelcometext 0.7s ease;
}
@keyframes eventwelcometext {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/*event page wrapper*/

.event-page-wrapper {
  margin: 2px 0;
  background: var(--color-background);
}

.event-page {
  width: 100%;
  margin: 0.6rem auto;
  max-width: 800px;
  padding: 0.7rem 0.1rem;
}
.event-page-title {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 900;
}
.event-page-title-itself {
  color: var(--color-primary);
}

/*event page card wrapper*/
.event-page-card-wrapper {
  width: 100%;
}

.event-page-card {
  position: relative;
  margin: 1rem 0.4rem;
  padding: 1rem 0.3rem;
  box-shadow: 0 1px 5px #ddd;
  border-radius: 3px;
}
.event-page-card-top {
  display: flex;
  flex-flow: row wrap;
  padding: 0.3rem;
  align-items: center;
}
.event-page-card-img {
  border-radius: 3px;
  width: 320px;
  height: 180px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  margin: 5px;
  transform: translateY(-5px);
  filter: saturate(120%) blur(0.2px);
}
.event-page-card-topic {
  margin: 0.4rem;
  padding: 0.4rem;
}
.event-page-card-title {
  color: var(--color-charcoal);
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  font-weight: 700;
  padding: 0.3rem 0.1rem;
  text-transform: capitalize;
}
.event-page-card-quote {
  margin: 5px 3px 3px 12px;
  color: grey;
  font-weight: 399;
  font-style: italic;
  text-transform: capitalize;
}
.event-page-card-quote-itself {
  font-weight: 700px;
  font-size: 2rem;
}
.event-page-card-date {
  margin: 5px 5px 5px 8px;
  padding: 2px 8px;
}
.event-page-card-date-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  fill: var(--color-primary);
}
.event-page-media-icon-wrapper {
  position: absolute;
  right: 2%;
  top: 3%;
}
.event-page-card-content {
  display: flex;
  margin: 0.7rem auto;
  padding: 0.75rem;
  color: grey;
  font-size: 0.98rem;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 780px) {
  /*gallery*/
  .gallery-flex {
    width: 100%;
  }

  /*blog*/
  .blog-card {
    max-width: 500px;
  }

  /*blog detail*/

  .blog-detail-img {
    height: 250px;
  }
}

@media only screen and (max-width: 600px) {
  /*gallery*/
  .gallery-flex {
    justify-content: center;
  }

  /*blog detail*/

  .blog-detail-img {
    height: 200px;
  }

  /*event page*/
  .event-page-media-icon-wrapper {
    position: relative;
  }
}
