/*admin login*/
.admin-each-component-wrapper {
  width: 98%;
  margin: 1rem auto;
  max-width: 700px;
  padding: 1rem 0.5rem;
  box-shadow: 0 0 5px #ddd;
}
.admin-login-wrapper {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-login {
  flex: 1 1 400px;
  max-width: 500px;
  box-shadow: 0 3px 8px #ddd;
  margin: 0.3rem 0.8rem;
  padding: 2rem 0.5rem;
  min-height: 320px;
  border-radius: 3px;
  transform: translateY(-15px);
}
.admin-login-topic {
  display: flex;
  align-items: center;
}
.admin-logo {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  box-shadow: 0 1px 5px #ddd;
  margin: 0.2rem 1rem;
}
.admin-login-title {
  font-weight: 900;
  font-size: 2.2rem;
  margin: 0.3rem 0.4rem;
}
.admin-login-form {
  margin: 1rem 0.2rem;
}
.incorrect-credentials {
  text-align: center;
  color: crimson;
  opacity: 0.7;
  font-weight: 550;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.admin-form-block {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0.9rem 0.1rem;
}
.admin-form-label {
  flex: 3 1 80px;
  color: grey;
  text-indent: 1rem;
}
.admin-form-input {
  flex: 5 1 120px;
  display: block;
  height: 35px;
  border: 1.2px solid #ddd;
  border-radius: 2px;
  max-width: 400px;
  margin: 10px 2px;
  background: transparent;
  padding: 0.3rem 0.3rem 0.3rem 0.8rem;
  transition: all 0.15s ease;
}
.admin-form-input:focus {
  border: 1px solid #ddd;
  outline: none;
  box-shadow: 0 0 5px #ddd;
}
.admin-form-button {
  flex: 1 1 200px;
  margin: 0.6rem auto;
  max-width: 420px;
  border-radius: 2px;
  box-shadow: 0 3px 8px #ddd;
  padding: 0.7rem 0.1rem;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: #fff;
  font-size: 1.05rem;
  font-weight: 600;
  transition: all 0.08s ease;
}
.admin-form-button:hover {
  opacity: 0.9;
}
.admin-form-button:focus {
  background: var(--color-333);
}

.admin-form-quote {
  margin: 0.4rem 0.1rem;
  color: grey;
}
.abux-link,
.forget-link {
  text-decoration: none;
  color: var(--color-primary);
  transition: all 0.05s ease;
}
.abux-link:hover,
.forget-link:hover {
  text-decoration: underline;
}

/*admin nav bar*/
.admin-navbar-wrapper {
  width: 100%;
  box-shadow: 0 3px 10px #ddd;
}
.admin-navbar {
  width: 100%;
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0.1rem auto;
  padding: 1rem 0.9rem;
}
.admin-navbar-menu-icon {
  flex: 1 1 100px;
  width: 2.1rem;
  height: 1.7rem;
  fill: var(--color-primary);
  margin: 0.1rem 0.8rem;
  padding: 0.2rem;
  border-radius: 8px;
  transition: all 0.1s ease;
}
.admin-navbar-menu-icon:hover {
  background: rgba(0, 0, 0, 0.1);
}

.admin-navbar-identity {
  flex: 1 1 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.admin-navbar-logout {
  text-decoration: none;
  color: var(--color-secondary);
  font-weight: 500;
  margin: 0.1rem 0.4rem;
  padding: 0.25rem 0.4rem;
  border: 2px solid #fff;
  border-radius: 3px;
  transition: all 0.1s ease;
  cursor: pointer;
}
.admin-navbar-logout:hover {
  border: 2px solid var(--color-secondary);
}
.admin-navbar-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: 800;
  overflow: hidden;
  background: rgba(0, 0, 200, 0.6);
  color: #fff;
  text-align: center;
  margin: 0.1rem 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-name {
}

/*admin slide bar*/
.admin-slide-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}
.admin-slide-bar-wrapper {
  position: relative;
  height: 100vh;
  width: 60%;
  padding: 0.3rem;
  min-width: 200px;
  max-width: 400px;
  background: #fff;
  z-index: 100;
}
.admin-slide-bar-wrapper-animate {
  animation: slidebar 0.6s ease;
  background: #fff;
  z-index: 100;
}
@keyframes slidebar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.admin-slide-bar-topic {
  margin: 0.8rem 0.1rem;
  display: flex;
  flex-flow: column nowrap;
}
.admin-slide-bar-title {
  color: var(--color-primary);
  font-weight: 900;
  text-align: center;
  font-size: 1.8rem;
  margin: 0.4rem 0.1rem;
}
.admin-slide-bar-user {
  margin: 0.2rem 0.1rem;
  text-align: center;
  color: grey;
}

/*droping dropper*/
.slide-bar-dropper-wrapper {
  color: var(--color-333);
  padding: 0.4rem 0.2rem;
  margin: 0.2rem;
  cursor: pointer;
}
.slide-bar-dropper {
  padding: 0.5rem 0.8rem;
  margin: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  box-shadow: 0 0 1px #ddd;
  border-radius: 2px;
}
.admin-drop-down-icon {
  width: 0.1rem;
  height: 0.1rem;
  transition: transform 0.1s ease;
}
.admin-drop-down-icon-animate {
  transform: rotate(-90deg);
  animation: transform 0.2s ease;
}
.slide-bar-droping {
  box-shadow: 0 0 2px #ddd;
  margin: 0.1rem;
}
.admin-slide-bar-link {
  display: block;
  width: 100%;
  color: var(--color-333);
  padding: 0.65rem 0.1rem;
  margin: 0.05rem 0.1rem;
  text-decoration: none;
  ont-weight: 500;
  text-indent: 0.9rem;
  border-radius: 2px;
}
.slide-bar-vanisher {
  position: absolute;
  top: 1%;
  left: 2%;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  display: flex;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

/*admin content*/
.admin-content-wrapper {
  margin: 0.7rem 0;
  padding: 0.8rem;
}

/*common invalid input */
.admin-invalid-input {
  text-indent: 0.7rem;
  margin: 0.1rem 0.1rem 0 0.1rem;
  color: crimson;
  opacity: 0.9;
  font-size: 0.8rem;
  z-index: 2;
}
.loading-button {
  opacity: 0.75;
  transition: all 0.1s ease;
}
/*pop up*/
.admin-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.4rem;
  animation: poping 0.75s ease;
  box-shadow: 0 0 8px #ddd;
  border-radius: 3px;
}
@keyframes poping {
  0% {
    transform: translateY(-130%);
  }
  100% {
    transform: translateY(0);
  }
}
.admin-popup-message {
  padding: 0.4rem 0.5rem;
  font-size: 0.95rem;
}
.popup-success {
  color: var(--color-green);
}
.popup-error {
  color: var(--color-secondary);
}

/*loading */
.admin-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.4rem;
  margin: 0.5rem 0.1rem;
}
.admin-loading {
  margin: 0.5rem;
  padding: 0.8rem 0.2rem;
  font-weight: 400;
  color: grey;
}
.admin-fetch-error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.4rem;
  margin: 0.5rem 0.1rem;
}
.admin-fetch-error {
  margin: 0.5rem;
  padding: 0.8rem 0.2rem;
  color: var(--color-secondary);
  font-weight: 410;
}

/*admin card*/
.admin-view-wrapper {
  margin: 0.3rem 0.1rem;
  padding: 0.4rem 0.1rem;
  position: relative;
}
.admin-view-title {
  text-align: center;
  margin: 0.4rem 0.1rem;
  padding: 0.1rem;
  color: var(--color-333);
}
.admin-view {
  max-width: 800px;
  margin: 0.2rem auto;
}

.admin-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.3rem;
  margin: 0.3rem 0.1rem;
  box-shadow: 0 0 5px #ddd;
  transition: all 0.1s ease;
  cursor: pointer;
}

.admin-card:hover {
  background: #eee;
}

.admin-card-img-wrapper {
  flex: 2 1 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-card-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  filter: saturate(115%);
  object-fit: cover;
}
.admin-card-title {
  flex: 2 1 100px;
  text-align: center;
}
.admin-card-delete-wrapper {
  flex: 1 1 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-delete-icon {
  width: 26px;
  height: 26px;
  stroke: var(--color-secondary);
  transition: all 0.1s ease;
  padding: 4px;
  border-radius: 50%;
  box-shadow: 0 0 5px #eee;
}
.admin-delete-icon:hover {
  opacity: 0.9;
  background: #ddd;
}

/*confirm popup*/
.admin-confirm-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-confirm-popup {
  padding: 1.4rem 0.5rem;
  flex: 1 1 200px;
  max-width: 350px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px #333;
}
.admin-confirm-popup-message {
  margin: 0.5rem 0.1;
  text-align: center;
  padding: 0.2rem;
}
.admin-confirm-button-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.8rem 0.1rem;
}
.admin-confirm-button {
  padding: 0.6rem 0.1rem;
  flex: 1 1 100px;
  font-weight: 420;
  margin: 0.3rem 0.2rem;
  transition: all 0.1s ease;
  background: #ddd;
  border: none;
}
.admin-confirm-button-ok {
  background: var(--color-secondary);
  color: #fff;
}
.admin-confirm-button:hover {
  opacity: 0.9;
}
